<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Add custom fields') }}</h3>
                    <p>{{ typeName }}</p>
                </div>

                <form @submit.prevent="addCustomField" class="invite-form">

                    <div class="form-group">
                        <input v-model="customField.label" type="text" :class="['form-control', {'has-error': errors['label']}]" :placeholder="$t('Label')">

                        <FormErrors v-if="errors[`label`]" :errors="errors[`label`]" />
                    </div>

                    <div v-if="typeName === 'Application'" class="form-group">
                        <Multiselect
                            v-model="customField.inputType"
                            :options="inputTypes"
                            :placeholder="$t('Input Type')"
                            :class="['form-control', {'has-error': errors['inputType']}]"
                        />
                        <FormErrors v-if="errors[`inputType`]" :errors="errors[`inputType`]" />
                    </div>

                    <div v-if="needValue" class="form-group">
                        <input v-model="customField.value" type="text" :class="['form-control', {'has-error': errors['value']}]" :placeholder="$t('Value')">
                        
                        <FormErrors v-if="errors[`value`]" :errors="errors[`value`]" />
                    </div>

                    <button class="btn btn-primary" :disabled="disabled">{{ $t('Add') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import {customFieldService} from '@/services/settings/customFieldService'
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';
import Multiselect from '@vueform/multiselect'

export default {
    name: "AddCustomFieldComponent",
    components: {
        Modal,
        FormErrors,
        Multiselect
    },
    data() {
        return {
            customField: {
                selectedType: null,
                label: null,
                inputType: null,
                value: null
            },


        }
    },
    computed: {
        inputTypes() {
            return this.$store.getters.customFieldInputTypes
        },
        needValue() {
            return (this.typeName === 'Application' && ['dropdown', 'radio', 'range'].includes(this.customField.inputType)) || (this.typeName !== 'Application')
        }
    },
    props: ['typeName'],
    methods: {
        addCustomField() {
            this.disabled = true

            this.customField.value = this.needValue
                ? this.customField.value
                : null

            this.customField.selectedType = this.typeName

            customFieldService.add(this.customField)
                .then(response => {
                    this.$store.commit('addCustomField', response.data.data)
                    this.$emit('closeModal')
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
                .finally(() => this.disabled = false)
        }
    }
}
</script>

<style scoped>

</style>