<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Edit custom field') }}</h3>
                    <p>{{ typeName }}</p>
                </div>
                <form @submit.prevent="updateCustomField" class="invite-form">
                    <div class="form-group">
                        <input v-model="customField.label" type="text" :class="['form-control', {'has-error': errors['label']}]" :placeholder="$t('Label')">

                        <FormErrors v-if="errors[`label`]" :errors="errors[`label`]" />
                    </div>

                    <div v-if="typeName === 'Application'" class="form-group">
                        
                        <Multiselect
                            v-model="customField.inputType"
                            :options="customFieldInputTypes"
                            :placeholder="$t('Input type')"
                            :class="['form-control', {'has-error': errors['inputType']}]"
                        />

                        <FormErrors v-if="errors[`inputType`]" :errors="errors[`inputType`]" />
                    </div>

                    <div v-if="needValue" class="form-group">
                        <input v-model="customField.value" type="text" :class="['form-control', {'has-error': errors['value']}]" :placeholder="$t('Value')">

                        <FormErrors v-if="errors[`value`]" :errors="errors[`value`]" />
                    </div>
                    <button class="btn btn-primary">{{ $t('Save') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import {customFieldService} from '@/services/settings/customFieldService'
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';
import Multiselect from '@vueform/multiselect'

export default {
    name: "EditCustomFieldComponent",
    components: {
        Modal,
        FormErrors,
        Multiselect
    },
    data() {
        return {

        }
    },
    computed: {
        customFieldInputTypes() {
            return this.$store.getters.customFieldInputTypes
        },
        needValue() {
            return (this.typeName === 'Application' && ['dropdown', 'radio', 'range'].includes(this.customField.inputType)) || (this.typeName !== 'Application')
        },
        typeName() {
            return this.customField.selectedType
        }
    },
    props: ['customField'],
    methods: {
        updateCustomField() {
            if (!this.needValue) {
                this.customField.value = null
            }

            customFieldService.update(this.customField.id, this.customField)
                .then(() => {
                    this.hideEditComponent()
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        }
    }
}
</script>

<style scoped>

</style>